export interface MakeFilter {
    text: string;
    models: string[];
    kilometers: string[];
    years: string[];
    price: string[];
    selected: boolean;
}
export interface ModelFilter {
    makes: string;
    text: string;
    kilometers: string[];
    years: string[];
    price: string[];
    selected: boolean;
}
export interface YearFilter {
    text: number;
    makes: string[];
    selected: boolean;
}
export interface ColorFilter {
    text: string;
    makes: string[];
    selected: boolean;
}
// filters
export enum FILTER_NAMES {
    MAKE_MODEL = 'Make & Model',
    AVAILABLE = 'Car Status',
    PRICE = 'price',
    DISCOUNTED = 'Discounted',
    MODEL_YEAR = 'year',
    KILOMETERS = 'kilometers',
    BODY_STYLE = 'Body Style',
    TRANSMISSION = 'Transmission',
    EMI = 'emi',
}
const currentYear = new Date().getFullYear() + 1;
const supportYearAgo = 13;
const FILTER_YEARS = Array.from(
    { length: supportYearAgo },
    (_, i) => currentYear - supportYearAgo + i
);
export const YEAR_FILTER_DATA = FILTER_YEARS.map(year => {
    const stringYear = String(year);
    return {
        text: stringYear,
        value: stringYear,
        selected: false,
    } as FilterDisplayItemData;
});

//enums
export enum PRICE {
    ONE = '100000', // from 100,000 to 199,999
    TWO = '200000',
    THREE = '300000',
    FOUR = '400000',
    FIVE = '500000',
    SIX = '600000',
    SEVEN = '700000',
    EIGHT = '800000',
    NINE = '900000',
    TEN = '1000000',
}
export enum KILOMETERS {
    ONE = '10000', // from 0 to 10,000
    TWO = '30000', // from 0 to 30,000
    THREE = '50000',
    FOUR = '75000',
    FIVE = '100000',
    SIX = '125000',
    SEVEN = '150000',
}
export enum BODY_TYPE {
    COUPE = 'coupe',
    SEDAN = 'sedan',
    SUV = 'suv',
    TRUCK = 'truck',
    VAN = 'van',
    CONVERTIBLE = 'convertible',
    MINIVAN = 'minivan',
    HATCHBACK = 'hatchback',
    FOUR_X_FOUR = '4X4',
}
export enum TRANSMISSION {
    AUTOMATIC = 'automatic',
    MANUAL = 'manual',
}

export interface FilterTabsInterface {
    text: string;
    selected: boolean;
    id: string | number;
    count: number;
}

// passed to filter ui components
export interface FilterDisplayItemData {
    text: string;
    selected: boolean;
    count: number;
    value: string;
}
export interface YearFilterDisplayItemData {
    text: number;
    selected: boolean;
    count: number;
    value: number;
}

export interface MakeFilterUiOject {
    text: string;
    selected: boolean;
    count: number;
    value: string;
    models: FilterDisplayItemData[];
}

// store data for a specific filter. ex: 2012:{make: [Kia, Tyota], count: 2}
export interface FilterItemData {
    value: string;
    makes: string[];
    count: number;
}

export interface FilterInnerCarInfoObject {
    kilometers: KILOMETERS;
    bodyType: BODY_TYPE;
    price: PRICE;
    transmission: TRANSMISSION;
}
// object htat represent the value and count of a single item. ex: {value: 2012, count: 1}
export interface FilterInnerObjectItem {
    value: string;
    count: number;
    cars: FilterInnerCarInfoObject[];
}
export interface ModelFilterObject {
    value: string;
    year: number;
}

export interface MakeFilterObject {
    models: Record<string, ModelFilterObject>;
}

export interface FilterTagMobileObject {
    text: string | number;
    filterType: string;
}

export enum CarStatus {
    AVAILABLE = 'available',
    RESERVED = 'reserve',
    BEING_SOLD = 'being_sold',
}
export enum CarStatusText {
    available = 'Available',
    reserve = 'Reserved',
    being_sold = 'Being Sold',
}
export enum CarDBStatus {
    RESERVED = 'RESERVED',
    UNRESERVED = 'UNRESERVED',
    BEING_SOLD = 'BEING_SOLD',
    SOLD = 'SOLD',
}
