import * as Yup from 'yup';
const prospectiveCustomerCreateCarDetails = Yup.object({
    makeAndModel: Yup.array()
        .of(
            Yup.object().shape({
                makeValue: Yup.string().required(),
                modelValue: Yup.string().required(),
            })
        )
        .nullable()
        .label('Make and model'),
    transmission: Yup.string().nullable().label('Transmission'),
    bodyType: Yup.string().nullable().label('Body Type'),
    paymentPlan: Yup.string().nullable().label('Payment Type'),
    downPayment: Yup.number().nullable().label('Down payment'),
    yearFrom: Yup.number().nullable().label('Year From'),
    yearTo: Yup.number().nullable().label('Year To'),
    priceFrom: Yup.number().nullable().label('Price From'),
    priceTo: Yup.number().nullable().label('Price To'),
    kilometrageFrom: Yup.number().nullable().label('Kilometrage From'),
    kilometrageTo: Yup.number().nullable().label('Kilometrage To'),
})
    .test(
        'required-fields',
        'At least one search field is required',
        function (value) {
            const {
                makeAndModel,
                transmission,
                bodyType,
                yearFrom,
                priceFrom,
                kilometrageFrom,
                yearTo,
                priceTo,
                kilometrageTo,
                paymentPlan,
                downPayment,
            } = value;

            const fields = [
                makeAndModel,
                transmission,
                bodyType,
                yearFrom,
                priceFrom,
                kilometrageFrom,
                yearTo,
                priceTo,
                kilometrageTo,
                paymentPlan,
                downPayment,
            ];

            const missingFields = fields.filter(
                field => field === undefined || field === null
            );
            if (missingFields.length === fields.length) {
                return this.createError({
                    message: 'At least one search field is required',
                });
            }

            return true;
        }
    )
    .test(
        'downpayment-field',
        'Down payment field can not be empty',
        function (value) {
            const { paymentPlan, downPayment } = value;

            if (paymentPlan === 'financing' && downPayment < 50000) {
                return this.createError({
                    message: 'Down payment field can not be empty',
                });
            }

            return true;
        }
    );

const prospectiveCustomerCreatePersonalDetails = Yup.object({
    customerName: Yup.string()
        .required('errors.pleaseEnterName')
        .label('Full Name'),
    customerPhone: Yup.string()
        .matches(/^01\d{9}$/, 'errors.phoneFormat')
        .required('errors.pleaseEnterPhone')
        .label('Mobile Phone Number'),
    customerEmail: Yup.string()
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'errors.enterValidEmail')
        .transform((curr, orig) => (orig === '' ? null : curr))
        .notRequired()
        .nullable()
        .label('Email'),
});

export const prospectiveCustomerCreateDto = [
    prospectiveCustomerCreateCarDetails,
    prospectiveCustomerCreatePersonalDetails,
];
