import { ReactNode } from 'react';

export interface ImperfectionReportSection {
    // name_ar: string;
    // name_en: string;
    // report: ImperfectionReportItem[];
    name_ar: string;
    name_en: string;
    section_order: number;
    section_faulty_icon: string;
    section_good_icon: string;
    items: ImperfectionReportItem[];
    subtitle_ar?: string;
    subtitle_en?: string;
}
export interface ImperfectionReportSectionGrouped {
    exterior: ImperfectionReportSection[];
    faulty: ImperfectionReportSection[];
    unFaulty: ImperfectionReportSection[];
    isRepainted: boolean;
    numberOfRepaintedPanels: number;
}

export enum IImperfectionReportItemTypes {
    GENERIC = 'GENERIC',
    TEXT_ONLY = 'TEXT_ONLY',
}

export interface ImperfectionReportItem {
    comment_ar: string;
    comment_en: string;
    images: string[];
    imperfection_item_id: string;
    name_ar: string;
    name_en: string;
    status: ImperfectionStatusEnum;
    tags: Tag[];
    vehicle_id: string;
    highlightedImages: string[];
    item_type: IImperfectionReportItemTypes;
}
export interface ImperfectionReportResult {
    success: boolean;
    statusCode: number;
    message: string;
    data: ImperfectionReportSection[];
}
export interface ImperfectionReportGrouppedResult {
    success: boolean;
    statusCode: number;
    message: string;
    data: ImperfectionReportSectionGrouped;
}

export enum ImperfectionStatusEnum {
    UnFaulty = 'UnFaulty',
    Faulty = 'Faulty',
    NotAvailable = 'N/A',
    Fixed = 'Fixed',
}
export enum ExpirementImperfectionReport {
    ORIGINAL_REPORT = 'ORIGINAL_REPORT',
    GROUPED_REPORT = 'GROUPED_REPORT',
    BINDING = 'BINDING',
}
export interface QCReportPoint {
    id: string;
    item_en: string;
    item_ar: string;
    status: string;
    commentAr: string;
    commentEn: string;
    image?: string;
    tags: Tag[];
}

export interface QCReportSection {
    section_en: string;
    section_ar: string;
    modified?: boolean;
    properties: QCReportPoint[];
    faultyPoints: number;
    fixedPoints: number;
}
export interface Tag {
    tag_en: string;
    tag_ar: string;
    tag_id: string;
    status: string;
}
export interface IQCReportUIProperty {
    id: string;
    name: string;
    value: string | ReactNode;
    comment: string;
    status: ImperfectionStatusEnum;
    tags?: string[];
}

// const REPORT_POINT_SKELETON: IQCReportUIProperty = {
//     id: '',
//     name: '',
//     value: '',
//     comment: '',
//     status: ImperfectionStatusEnum.NotAvailable,
//     tags: [],
// };
export interface ModalTagProps {
    status: ImperfectionStatusEnum;
}
// const tyresMap = new Map([
//     ['Front Right Tyre', { ...REPORT_POINT_SKELETON }],
//     ['Front Left Tyre', { ...REPORT_POINT_SKELETON }],
//     ['Rear Right Tyre', { ...REPORT_POINT_SKELETON }],
//     ['Rear Left Tyre', { ...REPORT_POINT_SKELETON }],
//     ['Spare Tyre', { ...REPORT_POINT_SKELETON }],
// ]);
// const exteriorMap = new Map([
//     ['Factory Paint', { ...REPORT_POINT_SKELETON }],
//     ['Repainted', { ...REPORT_POINT_SKELETON }],
//     ['Scratches', { ...REPORT_POINT_SKELETON }],
//     ['Hood', { ...REPORT_POINT_SKELETON }],
//     ['Trunk', { ...REPORT_POINT_SKELETON }],

//     ['Front Bumper', { ...REPORT_POINT_SKELETON }],
//     ['Rear Bumper', { ...REPORT_POINT_SKELETON }],
//     ['Front Right Fender', { ...REPORT_POINT_SKELETON }],
//     ['Right Quarter Panel', { ...REPORT_POINT_SKELETON }],
//     ['Front Left Fender', { ...REPORT_POINT_SKELETON }],
//     ['Left Quarter Panel', { ...REPORT_POINT_SKELETON }],
//     ['Right Step Bumper', { ...REPORT_POINT_SKELETON }],
//     ['Left Step Bumper', { ...REPORT_POINT_SKELETON }],

//     ['Right-Side Mirror', { ...REPORT_POINT_SKELETON }],
//     ['Left-Side Mirror', { ...REPORT_POINT_SKELETON }],

//     ['Right Front Door', { ...REPORT_POINT_SKELETON }],
//     ['Left Front Door', { ...REPORT_POINT_SKELETON }],
//     ['Right Rear Door', { ...REPORT_POINT_SKELETON }],
//     ['Left Rear Door', { ...REPORT_POINT_SKELETON }],

//     ['Roof', { ...REPORT_POINT_SKELETON }],
//     ['Front Windshield', { ...REPORT_POINT_SKELETON }],
//     ['Rear Windshield', { ...REPORT_POINT_SKELETON }],

//     // ['Back Glass', { ...REPORT_POINT_SKELETON }],
//     ['Right Front Glass', { ...REPORT_POINT_SKELETON }],
//     ['Left Front Glass', { ...REPORT_POINT_SKELETON }],
//     ['Rear Right Glass', { ...REPORT_POINT_SKELETON }],
//     ['Rear Left Glass', { ...REPORT_POINT_SKELETON }],

//     ['Head Lights', { ...REPORT_POINT_SKELETON }],
//     ['Tail Lights', { ...REPORT_POINT_SKELETON }],

//     ['Car Rims', { ...REPORT_POINT_SKELETON }],
// ]);
// const interiorMap = new Map([
//     ['Front carpet and floor mats', { ...REPORT_POINT_SKELETON }],
//     ['Rear carpet and floor mats', { ...REPORT_POINT_SKELETON }],
//     ['Driver door interior lining', { ...REPORT_POINT_SKELETON }],
//     ['Front passenger door interior lining', { ...REPORT_POINT_SKELETON }],
//     ['Rear left door interior lining', { ...REPORT_POINT_SKELETON }],
//     ['Rear right door interior lining', { ...REPORT_POINT_SKELETON }],
//     ['Roof Lining', { ...REPORT_POINT_SKELETON }],
//     ['Air Conditioning / AC', { ...REPORT_POINT_SKELETON }],
//     ['Driver seat lining', { ...REPORT_POINT_SKELETON }],
//     ['Front passenger seat lining', { ...REPORT_POINT_SKELETON }],
//     ['Rear seating lining', { ...REPORT_POINT_SKELETON }],
// ]);
// const mechanicalMap = new Map([
//     ['No back compression', { ...REPORT_POINT_SKELETON }],
//     ['Ignition working fine', { ...REPORT_POINT_SKELETON }],
//     ['Battery in good condition', { ...REPORT_POINT_SKELETON }],
//     ['Steering system working fine', { ...REPORT_POINT_SKELETON }],
//     ['Alternator belt is working smoothly', { ...REPORT_POINT_SKELETON }],
//     ['AC compressor is working fine', { ...REPORT_POINT_SKELETON }],
//     ['Coolant cycle is efficient', { ...REPORT_POINT_SKELETON }],
//     ['Radiator in good condition', { ...REPORT_POINT_SKELETON }],
//     ['Engine does not emmit abnormal sounds', { ...REPORT_POINT_SKELETON }],
// ]);
// const electricalMap = new Map([
//     ['Audio system', { ...REPORT_POINT_SKELETON }],
//     ['Steering Handle', { ...REPORT_POINT_SKELETON }],
//     ['Digital Cluster Panel', { ...REPORT_POINT_SKELETON }],
//     ['Combination Switch', { ...REPORT_POINT_SKELETON }],
//     ['Dashboard', { ...REPORT_POINT_SKELETON }],
//     ['Gear lever', { ...REPORT_POINT_SKELETON }],
//     ['Infotainment System', { ...REPORT_POINT_SKELETON }],
//     ['Lock System', { ...REPORT_POINT_SKELETON }],
//     ['Battery', { ...REPORT_POINT_SKELETON }],
//     ['Multimedia connectivity', { ...REPORT_POINT_SKELETON }],
// ]);
// const suspensionMap = new Map([
//     ['front right suspention', { ...REPORT_POINT_SKELETON }],
//     ['front left suspension', { ...REPORT_POINT_SKELETON }],
//     ['steering box', { ...REPORT_POINT_SKELETON }],
//     ['engine mounts', { ...REPORT_POINT_SKELETON }],
//     ['gear box mounts', { ...REPORT_POINT_SKELETON }],
//     ['lower tension', { ...REPORT_POINT_SKELETON }],
// ]);
// export const IMPERFECTIONS_SKELETON = {
//     Tyres: tyresMap,
//     Exterior: exteriorMap,
//     Interior: interiorMap,

//     Mechanical: mechanicalMap,

//     Electricals: electricalMap,

//     Suspension: suspensionMap,
// };
