import { Showroom } from '../Showroom.types';
import { AllowedVehicleBookingTypesBinary } from '../booking.types';
import { TrimInterface } from '../cars-listings';
import { LenderWithInterestRates } from './lender.types';

export interface Make {
    id: string;
    enName: string;
    arName: string;
    image: string;
}

export interface Model {
    id: string;
    enName: string;
    arName: string;
    years: string[];
    yearsKm: { year: string; maxKm: string }[];
}
export interface bookingTypeInput {
    bookingType : boolean,
    availableBookingTypes:number
}
export interface CarInfoType {
    id: string;
    make: string;
    model: string;
    year: number;
    kilometers: number;
    insuranceCompany: string;
    licenseValidTo: string;
    insuranceValidity: string;
    numberOfPrevOwners: number;
    transmission: string;
    installmentPrice: number;
    totalPrice: number;
    features?: any;
}
export interface DisplayedCarInfoInterface {
    id: string;
    make: string;
    model: string;
    year: number;
    kilometers: number;
    trim: TrimInterface;
    installmentPrice: number | string;
    sylndrSellingPrice: string;
    transmission: string;
    isReserved: boolean;
    carName: string;
    isSold: boolean;
    isPublished: boolean;
    isPurchaseInProgress: boolean;
    showroom: Showroom;
    discountPrice: number;
    registrationFees: number | null;
    bookingType: AllowedVehicleBookingTypesBinary;
    isNewlyAdded: boolean;
}

export interface IVehicleFeature {
    id: string;
    name: string;
    featureProperties: IFeatureProprty[];
}

export interface IFeatureProprty {
    id?: string;
    name: string;
    value: string;
    type?: string;
}
export enum ExpirementPriceBreakdown {
    PRICE_NOT_INCLUDED = 'PRICE_NOT_INCLUDED',
    PRICE_INCLUDED = 'PRICE_INCLUDED',
    BINDING = 'BINDING',
}
export interface CarDetailsType {
    id: string;
    sfVehicleName: string;
    make: string;
    model: string;
    year: number;
    kilometers: number;
    color: string;
    bodyStyle: string;
    transmission: string;
    trafficUnit: string;
    liscenseValidityDate: string;
    insuranceValidityDate: string;
    insuranceCompany: string;
    sylndrSellingPrice: string;
    engineCapacity: string;
    acceptFinancing: boolean;
    licenseValid: boolean;
    licenseType: string;
    plateNumber: string;
    noOfPreviousOwners: number;
    registrationFees: number | null;
    displayedCarName: null;
    showroomId: object | string;
    vehicleFeature: IVehicleFeature[];
    images: string[];
    interior3d: string;
    isReserved: boolean;
    lenders: LenderWithInterestRates[];
    installmentPrice?: string | number;
    trim: any;
    isSold: boolean;
    isPublished: boolean;
    isPurchaseInProgress: boolean;
    newGallery: boolean;
    displayingPrice: number;
    discountPrice: number;
    showroom: Showroom;
    highlights?: ICarHighlights;
    bookingType: number;
    isNewlyAdded: boolean;
    status: string;
    basePrice: number;
    warrantyPrice: number;
}
export interface ICarHighlights {
    ar: string[];
    en: string[];
}
export interface CarFeatureInterface {
    id: string;
    name: string;
    type: string;
    value: string | number;
}
export interface CarFeaturesInterface {
    name: string;
    id: string;
    featureProperties: CarFeatureInterface[];
}
export interface ShowRoomInterface {
    address?: { ar: string; en: string };
    createdAt?: string;
    daysOpenForBooking?: number;
    id?: string;
    images?: string[];
    instructions?: { ar: string; en: string };
    name?: { ar: string; en: string };
    pinLocation?: { lat: string; long: string };
    prepTime?: number;
    sameCarSlot?: boolean;
    showroomScheduleId?: string;
    showroom_schedule_id?: string;
    slotDuration?: number;
    updatedAt?: string;
    openFrom?: string;
    openTo?: string;
}
export interface CarDetailsInterface {
    acceptFinancing: boolean;
    bodyStyle: string;
    color: string;
    displayedCarName: string;
    engineCapacity: string;
    id?: string;
    insuranceCompany: string;
    insuranceValidityDate: string;
    kilometers: string;
    licenseType: string;
    licenseValid: true;
    liscenseValidityDate: string;
    make: string;
    model: string;
    noOfPreviousOwners: string | number;
    plateNumber: string;
    registrationFees: number | string;
    sfVehicleName: string;
    showroom?: ShowRoomInterface;
    showroomId?: string;
    sylndrSellingPrice: string;
    trafficUnit: string;
    transmission: string;
    vehicleFeature?: CarFeaturesInterface[];
    year: number | string;
    trim: TrimInterface;
    installmentPrice: string | number;
    lenders: LenderWithInterestRates[];
    isReserved: boolean;
}

export interface GetCarDetails {
    status: string;
    statusCode: number;
    data: CarDetailsInterface;
}

export interface GetCarDetailsResponse {
    statusCode: number;
    success: boolean;
    data: CarDetailsType;
}

export interface GetCarShowroomCalendarResponse {
    statusCode: number;
    success: boolean;
    message: string;
    data: any;
}

export interface ValidateTimeSlotResponse {
    success: boolean;
    statusCode: number;
    message: string;
}

export interface AreasData {
    nameEn: string;
    nameAr: string;
    discount?: number;
}
export interface CitiesAreasData {
    nameEn: string;
    nameAr: string;
    areas: AreasData[];
    id: string;
}
export interface GetCitiesAreas {
    statusCode: string | number;
    data: CitiesAreasData[];
    message: string;
}

export interface GetBookingAndVehicleDetailsData {
    name: string | undefined;
    id: string;
    userId: string;
    bookingDate: string;
    timeslotId: string;
    dayId: string;
    phone?: string | undefined;
    showroomScheduleId?: string | undefined;
    showroomId: string;
    vehicleId: string;
    paymentOTId?: string | null;
    bookingType?: 'SEE' | 'RESERVE';
    bookingStatus?: any;
    paymentStatus?: any;
    reserveType?: 'HOME' | 'SHOWROOM';
    bookingFees?: number;
    day?: any;
    timeslot?: any;
    vehicle?: any;
    clients?: any;
    createdAt: Date;
    updatedAt: Date;
}
export interface GetBookingAndVehicleDetails {
    statusCode: number;
    message: string;
    data: GetBookingAndVehicleDetailsData;
}

// Feature Skeleton

const FEATURE_POINT_SKELETON: CarFeatureInterface = {
    id: '',
    name: '',
    value: '',
    type: '',
};
const performanceMap = new Map([
    ['FuelType', { ...FEATURE_POINT_SKELETON }],
    ['FuelTankCapacity', { ...FEATURE_POINT_SKELETON }],
    ['MaximumSpeed', { ...FEATURE_POINT_SKELETON }],
    ['Cylinders', { ...FEATURE_POINT_SKELETON }],
    ['Gear_Shifts', { ...FEATURE_POINT_SKELETON }],
    ['AccelerationFrom_0_100_KmHr', { ...FEATURE_POINT_SKELETON }],
]);
const comfortAndConvienceMap = new Map([
    ['Air_Conditioner', { ...FEATURE_POINT_SKELETON }],
    ['AirConditionerController', { ...FEATURE_POINT_SKELETON }],
    ['ElectricDriverSeat', { ...FEATURE_POINT_SKELETON }],
    ['ElectricPassengerSeat', { ...FEATURE_POINT_SKELETON }],
    ['DriverSeatHeater', { ...FEATURE_POINT_SKELETON }],
    ['PassengerSeatHeater', { ...FEATURE_POINT_SKELETON }],
    ['PassengerSeatMassage', { ...FEATURE_POINT_SKELETON }],
    ['DriverSeatMassage', { ...FEATURE_POINT_SKELETON }],
    ['OutsideTemperatureDisplay', { ...FEATURE_POINT_SKELETON }],
    ['SteeringAdjustment', { ...FEATURE_POINT_SKELETON }],
    ['RearArmrest', { ...FEATURE_POINT_SKELETON }],
    ['RearAC', { ...FEATURE_POINT_SKELETON }],
]);

const entertainmentMap = new Map([
    ['X360ViewCamera', { ...FEATURE_POINT_SKELETON }],
    ['RearViewCamera', { ...FEATURE_POINT_SKELETON }],
    ['BluetoothCompatibility', { ...FEATURE_POINT_SKELETON }],
    ['FrontViewCamera', { ...FEATURE_POINT_SKELETON }],
    ['RemoteControlAudioSystem', { ...FEATURE_POINT_SKELETON }],
    ['GPSNavigationSystem', { ...FEATURE_POINT_SKELETON }],
    ['CDPlayer', { ...FEATURE_POINT_SKELETON }],
    ['SpeakersRear', { ...FEATURE_POINT_SKELETON }],
    ['SpeakersFront', { ...FEATURE_POINT_SKELETON }],
    ['VoiceCommand', { ...FEATURE_POINT_SKELETON }],
]);

const interiorFeaturesMap = new Map([
    ['MultiFunctionSteeringWheel', { ...FEATURE_POINT_SKELETON }],
    ['LeatherSteeringWheel', { ...FEATURE_POINT_SKELETON }],
    ['LeatherSeats', { ...FEATURE_POINT_SKELETON }],
    ['RearParkingSensors', { ...FEATURE_POINT_SKELETON }],
    ['KeylessEntry', { ...FEATURE_POINT_SKELETON }],
    ['AmbientLighting', { ...FEATURE_POINT_SKELETON }],
]);
const safteyFeaturesMap = new Map([
    ['AirbagsNumber', { ...FEATURE_POINT_SKELETON }],
    ['BlindSpotMonitor', { ...FEATURE_POINT_SKELETON }],
    ['Handbrake', { ...FEATURE_POINT_SKELETON }],
    ['ElectronicBrakeForceDistributionEBD', { ...FEATURE_POINT_SKELETON }],
    ['ElectronicStabilityProgramESP', { ...FEATURE_POINT_SKELETON }],
    ['AutoStartStopFunction', { ...FEATURE_POINT_SKELETON }],
    ['CruiseControl', { ...FEATURE_POINT_SKELETON }],
    ['TractionControl', { ...FEATURE_POINT_SKELETON }],
    ['Immobilizer', { ...FEATURE_POINT_SKELETON }],
    ['ParkingAssist', { ...FEATURE_POINT_SKELETON }],
    ['CenterLock', { ...FEATURE_POINT_SKELETON }],
    ['FollowMeHomeHeadlamps', { ...FEATURE_POINT_SKELETON }],
    ['SpeedSensingDoorLock', { ...FEATURE_POINT_SKELETON }],
    ['LowFuelLevelWarning', { ...FEATURE_POINT_SKELETON }],
]);

const exteriorFeaturesMap = new Map([
    ['RainSensor', { ...FEATURE_POINT_SKELETON }],
    ['RainSensingWipers', { ...FEATURE_POINT_SKELETON }],
    ['RearWiper', { ...FEATURE_POINT_SKELETON }],
    ['AutoFoldingMirrors', { ...FEATURE_POINT_SKELETON }],
    ['PowerMirrors', { ...FEATURE_POINT_SKELETON }],
    ['RemoteControlledBoot', { ...FEATURE_POINT_SKELETON }],
    ['BootLidOpener', { ...FEATURE_POINT_SKELETON }],
    ['TyreSize', { ...FEATURE_POINT_SKELETON }],
    ['TrimSize', { ...FEATURE_POINT_SKELETON }],
    ['AlloyWheelSize', { ...FEATURE_POINT_SKELETON }],
    ['DaytimeLED', { ...FEATURE_POINT_SKELETON }],
    ['PanoramaRoof', { ...FEATURE_POINT_SKELETON }],
    ['RemoteFuelLidOpener', { ...FEATURE_POINT_SKELETON }],
    ['FogLamps', { ...FEATURE_POINT_SKELETON }],
]);
const dimensionsFeaturesMap = new Map([
    ['Length', { ...FEATURE_POINT_SKELETON }],
    ['Width', { ...FEATURE_POINT_SKELETON }],
    ['Height', { ...FEATURE_POINT_SKELETON }],
    ['SeatingCapacity', { ...FEATURE_POINT_SKELETON }],
    ['BootCapacity', { ...FEATURE_POINT_SKELETON }],
]);
export const Feature_SKELETON = {
    PerformanceFeatures: performanceMap,
    ComfortFeatures: comfortAndConvienceMap,
    EntertainmentFeatures: entertainmentMap,
    SafteyFeatures: safteyFeaturesMap,
    InteriorFeatures: interiorFeaturesMap,
    ExteriorFeatures: exteriorFeaturesMap,
    DimensionFeatures: dimensionsFeaturesMap,
};

export * from './imperfection-report.types';
export * from './lender.types';
