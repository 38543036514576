export enum BODY_IMPERFECTION_TYPE {
    WARNING = 'warning',
    FEATURE = 'feature',
}

export interface vehicle3DFramesResponse {
    frameId: string;
    frameNumber: string;
    framePoints: framePoint[];
}

export interface framePoint {
    coordinates: { top: string; left: string };
    createdAt?: string;
    frameId?: string;
    frame_id?: string;
    id?: string;
    image: string;
    imperfectionItemId?: string;
    type: BODY_IMPERFECTION_TYPE;
    searchTags?: Tag[];
    section: string;
    imperfection_item_name: string;
}

interface Tag {
    tag_en: string;
    tag_ar: string;
    tag_id: string;
}

export type TImperfectionPoint = framePoint & { label: string };
export type TImperfectionsObj = Record<string, TImperfectionPoint[]>;
