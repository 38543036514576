import { Lender } from '../car-details';

export interface InterestRate {
    id: string;
    lender: Partial<Lender>;
    downpayment: number;
    interestRate: number;
    carSellBan: boolean;
    active: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export interface CarPrice {
    id: string;
    sylndrSellingPrice: number;
    installmentPrice: number;
}

export interface MinAndMaxPrices {
    min: CarPrice;
    max: CarPrice;
}
export interface IMinAndMaxPricesResult {
    data: MinAndMaxPrices;
    status: number;
}

export enum EFinanceType {
    CAR_PRICE = 'car-price',
    EMI = 'emi',
}
export enum EFinanceStateUpdateValue {
    carPrice = 'carPrice',
    monthlyInstallments = 'monthlyInstallments',
}
export interface MinAndMaxInterestRates {
    min: number;
    max: number;
}
export interface IMinAndMaxInterestRatesResult {
    data: MinAndMaxInterestRates;
    status: number;
}
