export enum AuthStepsEnum {
    phone = 'phone',
    otp = 'otp',
    name = 'name',
    email = 'email',
    profile = 'profile',
    spinner = 'spinner',
}

export interface IAuthGetOtpBody_RESULT {
    payload: null | object;
    message: string;
    status: number;
}
export interface IAuthConfirmOtpResponse {
    payload: {
        data: string;
    };
    message: string;
    status: number;
}
export interface IAuthUpdateInfoResponse {
    payload: {
        data: string;
    };
    message: string;
    statusCode: number;
}
export interface IAuthGetOtpBody {
    phone: string;
}

export interface IAuthGetUpdatedToken_RESULT {
    payload: {
        data: string;
    };
    message: string;
    statusCode: number;
}

export interface IAuth_REDUCER {
    progressValue: number;
    currentStep: AuthStepsEnum;
    phone: string;
    otp: string;
    name: string;
    email: string;
    userId: string;
    showProfileCard: boolean;
    isLoggedIn: boolean;
    redirectUrl?: string;
}

export interface IUpdateUserInfoBody {
    name: string;
    email: string;
}
export interface IUpdateUserInfoRequestParameters {
    id: string;
    name?: string;
    email?: string;
    emptyEmail?: boolean;
}
