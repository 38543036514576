import {
    IContanctInfo_SELL,
    ICoordinate,
    IKilometrage_SELL,
    ILocation,
    IMake_SELL,
    IModel_SELL,
    IRegion_SELL,
    ISellCarForm,
    ITrafficPoint_SELL,
    ITransmission_SELL,
    IYear_SELL,
} from '@/types';

export enum EInspectionType {
    WAREHOUSE = 'warehouse',
    HOME = 'HomeInspection',
}

export enum ExpirementInspectionType {
    WAREHOUSE = 'WAREHOUSE',
    HOME = 'HOME',
}

export enum ExpirementBookingOTPOrder {
    BINDING = 'BINDING',
    PRE = 'PRE',
    POST = 'POST',
}

export enum ExperimentLastBookingDayStatus {
    BINDING = 'BINDING',
    DISABLED = 'DISABLED',
    ENABLED = 'ENABLED',
}

export enum ExpirementBookingStepsType {
    BINDING = 'BINDING',
    MERGED = 'MERGED',
    SEPARATED = 'SEPARATED',
}
interface SylndrBenefitsType {
    url: string;
    benefitEn: string;
    benefitAr: string;
}
export interface HubDetails {
    workFromDayEN: string;
    workFromDayAR: string;
    workToDayEN: string;
    workToDayAR: string;
    workFromTimeEN: string;
    workFromTimeAR: string;
    workToTimeEN: string;
    workToTimeAR: string;
    titleEN: string;
    titleAR: string;
    addressEN: string;
    addressAR: string;
    detailedAddressEN: string;
    detailedAddressAR: string;
    location: ILocation;
    images: string[];
    id: string;
    workingAllDays: boolean;
    hubGoogleLocation: string;
    benefits: SylndrBenefitsType[];
}
export type TInspectionType = EInspectionType;
export type TStepperPath = 'path1' | 'path2';

export interface IStepperItem_SELL_NEW {
    // allowed and activated are controls that are set automatically depending on the data
    allowed: boolean;
    activated: boolean;
    manuallyDisabled: boolean; // to manually disable a step when needed
    icon: string;
    label: StepperStep;
    step: number;
    value: string;
    valueAr: string;
    inShoppingList: boolean;
    iconValue?: string;
    backgroundColor: string;
    mainIcon: string;
    showAlert: boolean;
    pathOneOnly: boolean;
}

export interface IStepper_SELL_Reducer {
    stepper: IStepperItem_SELL_NEW[];
    currentStep: IStepperItem_SELL_NEW;
    path: TStepperPath;
}

export interface IFormData_SELL_Reducer {
    formDataItems: ISellCarFormDataItem[];
    baseFormData: ISellCarForm;
}

export interface IStepper_SELL_NEW {
    make: IStepperItem_SELL_NEW;
    model: IStepperItem_SELL_NEW;
    region: IStepperItem_SELL_NEW;
    kilometers: IStepperItem_SELL_NEW;
    contactInfo: IStepperItem_SELL_NEW;
    year: IStepperItem_SELL_NEW;
    otherinfo: IStepperItem_SELL_NEW;
    inspectionType: IStepperItem_SELL_NEW;
}

export type StepperStep = keyof IStepper_SELL_NEW;

export interface IStepperIntializer {
    label: StepperStep;
    pathOneOnly: boolean;
    icon: string;
    activated?: boolean;
    allowed?: boolean;
    manuallyDisabled?: boolean;
}
export enum ExpirementNoAvailableBookingDaysType {
    BINDING = 'BINDING',
    THREE = 'THREE',
    FOUR = 'FOUR',
}
export interface IStepValues {
    value: string;
    valueAr: string;
    inShoppingList: boolean;
    iconValue?: string;
    backgroundColor: string;
    mainIcon?: string;
}

export interface IReceiptForSellForm {
    label: StepperStep;
    value: string;
    iconValue?: string;
    icon: string;
}

export type TFormItemKey = keyof ISellCarForm;
export type TSellFormDataItems =
    | IMake_SELL
    | IModel_SELL
    | IKilometrage_SELL
    | IYear_SELL
    | IRegion_SELL
    | IContanctInfo_SELL
    | ITrafficPoint_SELL
    | ITransmission_SELL
    | TInspectionType;

export interface ISellCarFormDataItem {
    key: TFormItemKey;
    dataItem: TSellFormDataItems;
}

export interface ISmallArea {
    id: string;
    nameEn: string;
    nameAr: string;
    warehouse: boolean;
    serviceable: boolean;
    selected: boolean;
    showAlert: boolean;
    polygon: ICoordinate[];
}

export interface ILocationDropDown {
    id: string;
    bigAreaEn: string;
    bigAreaAr: string;
    expanded: boolean;
    smallArea: ISmallArea[];
}

export enum EInspectionTypeSelectionVariants {
    ORIGINAL = 'ORIGINAL',
    HOME = 'HOME',
    WAREHOUSE = 'WAREHOUSE',
}
