export interface createProspectiveCustomer {
    makeAndModel?: { makeValue: string; modelValue: string }[];
    transmission?: string;
    bodyType?: string;
    yearFrom?: number;
    yearTo?: number;
    priceFrom?: number;
    priceTo?: number;
    kilometrageFrom?: number;
    kilometrageTo?: number;
    customerName: string;
    customerPhone: string;
    customerEmail?: string;
    contacted?: boolean;
    lang: 'en' | 'ar';
}
export interface MakeAndModelReducer {
    modelValue: string;
    makeValue: string;
    icon?: any;
    label: string;
    makeLabel: string;
}
export interface MakeAndModelAllFlagReducer {
    makeValue: string;
    icon: any;
    makeLabel: string;
    modelLabel: string;
}
export interface ActiveMake {
    enName: string;
    arName: string;
}
export interface createProspectiveCustomerResponse {
    statusCode: number;
    message: string;
    data: createProspectiveCustomer & { id: string };
}

export interface IStepperItem_CreateAlert {
    label: 'carDetails' | 'personalInformation';
    subtitle: 'carDetailsSubtitle' | 'personalInformationSubtitle';
    step: number;
}
export interface IStepper_CreateAlert {
    carDetails: IStepperItem_CreateAlert;
    personalInformation: IStepperItem_CreateAlert;
}

interface ICreateAlertThankYouItem {
    label: string;
    value: string;
    icon: string;
    valueIcon?: string;
}

export interface ICreateAlertThankYou {
    make?: ICreateAlertThankYouItem;
    model?: ICreateAlertThankYouItem;
    year?: ICreateAlertThankYouItem;
    kilometers?: ICreateAlertThankYouItem;
    price?: ICreateAlertThankYouItem;
    transmission?: ICreateAlertThankYouItem;
    bodyType?: ICreateAlertThankYouItem;
}

export * from './prospective-customer.dto';
