export * from './why-sylndr';
export * from './car-finance';
export * from './faqs';
export * from './car-details';
export * from './sell-car-form.types';
export * from './home-page';
export * from './cars-listings';
export * from './finance-application.types';
export * from './booking.types';
export * from './next.types';
export * from './filters';
export * from './redux-types';
export * from './create-alert';
export * from './checkout';
export * from './Showroom.types';
export * from './redux-state.types';
export * from './acquistion';
export * from './location.types';
export * from './testimonials.types';
export * from './visit-details';
export * from './whatsIncluded';
export * from './imperfections-3d.types';
export * from './auth';
export * from './otp';
export * from './user';
export * from './payment-link';
export * from './customer-profile';

export interface IGenericResponse {
    message: string;
    statusCode: number;
}

export function isText(data: unknown): data is string {
    return typeof data === 'string';
}
