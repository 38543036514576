export interface TrimInterface {
    ar: string;
    en: string;
}
export interface CarListingData {
    acceptFinancing: boolean;
    bodyStyle: string;
    color: string;
    displayedCarName: string;
    engineCapacity: string;
    id: string;
    insuranceCompany: string;
    insuranceValidityDate: string;
    kilometers: string;
    licenseType: string;
    licenseValid: boolean;
    liscenseValidityDate: string;
    sfVehicleId: string;
    make: string;
    model: string;
    noOfPreviousOwners: number;
    plateNumber: string;
    registrationFees: number;
    sfVehicleName: string;
    showroomId: string;
    sylndrSellingPrice: string;
    trafficUnit: string;
    transmission: string;
    year: number;
    installmentPrice: number | string;
    images: string[];
    trim: TrimInterface;
    isReserved: boolean;
    isPurchaseInProgress: boolean;
    discountPrice: number;
    isNewlyAdded: boolean;
    updatedAt: Date;
    publishedAt?: string;
    status: string;
}

export interface GetCarListing {
    statusCode: string | number;
    data: CarListingData[];
    success: boolean;
    total: number;
}

export interface ICarListingReducer {
    listings: CarListingData[];
    displayedListings: CarListingData[];
    filteredListings: CarListingData[];
    carsFoundAfterFilter: number;
}

export interface BannerData {
    id: string;
    enName: string;
    arName: string;
    enDesktopUrl: string;
    arDesktopUrl: string;
    enMobileUrl: string;
    arMobileUrl: string;
    order: number;
    destinationUrl: string;
}
export interface GetBanners {
    data: BannerData[];
}
export interface ISearchTerms {
    makeModelTree: Record<
        string,
        {
            make: string;
            modelsSlug: string[];
            models: Record<string, string>;
        }
    >;
    modelsTree: Record<
        string,
        {
            make: string;
            model: string;
        }
    >;
    priceTerms: {
        min: number;
        max: number;
    };
    emiTerms: {
        min: number;
        max: number;
    };
    bodyStyleTerms: {
        bodyStylesRaw: string[];
        bodyStyles: Record<string, string>;
    };
}
export enum IServerRetailFiltersKeys {
    make = 'make',
    model = 'model',
    transmission = 'transmission',
    kilometers = 'kilometers',
    year = 'year',
    price = 'price',
    status = 'status',
    bodystyle = 'bodystyle',
    emi = 'emi',
    discounted = 'discounted',
}
export type IServerRetailFilters = Record<IServerRetailFiltersKeys, string>;
export const defaultSearchTermsResponse = {
    modelsTree: {},
    makeModelTree: {},
    priceTerms: {
        min: 250000,
        max: 3000000,
    },
    emiTerms: {
        min: 5000,
        max: 25000,
    },
    bodyStyleTerms: {
        bodyStylesRaw: [],
        bodyStyles: {},
    },
};
export const defaultEmiFilter = {
    min: -Infinity,
    max: Infinity,
};
export const defaultPriceFilter = {
    min: -Infinity,
    max: Infinity,
};
export const defaultYearFilter = {
    min: -Infinity,
    max: Infinity,
};
export const getDefaultIClientRetailFilters = (): IClientRetailFilters => {
    const defaultState = {
        makeModel: {},
        transmission: {},
        bodystyle: {},
        price: { ...defaultPriceFilter },
        emi: { ...defaultEmiFilter },
        year: { ...defaultYearFilter },
        discounted: false,
    };
    return { ...defaultState };
};

export interface IClientRetailFilters {
    makeModel: Record<
        string,
        {
            make: string;
            models: Record<string, string>;
        }
    >;

    transmission: Record<string, string>;
    kilometers?: number;
    year: {
        min: number;
        max: number;
    };
    price: {
        min: number;
        max: number;
    };
    emi: {
        min: number;
        max: number;
    };
    status?: string;
    bodystyle: Record<string, string>;
    discounted?: boolean;
}
export type IEsSorting = Record<string, 'asc' | 'desc'>[];
