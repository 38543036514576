import { AVAILABLE_LANGUAGES } from '@/constants';

export enum EPaymentLinkStatus {
    'ACTIVE' = 'ACTIVE',
    'EXPIRED' = 'EXPIRED',
    'CONFIRMED' = 'CONFIRMED',
}

export enum EPaymentLinkPageLoadingStatus {
    'LOADING' = 'LOADING',
}

export type PaymentLinkPageStatusType =
    | EPaymentLinkStatus
    | EPaymentLinkPageLoadingStatus;

export interface IGeneratePaymentLinkPayload {
    bookingId: string;
    personalIdentification: string;
}

export interface IPaymentLinkVehicle {
    discountPrice: number;
    installmentPrice: string;
    make: string;
    model: string;
    sylndrSellingPrice: string;
    year: number;
    images: string[];
}

export interface IPaymentLink {
    id: string;
    vehicleId: string;
    sfVehicleName: string;
    bookingId: string;
    status: EPaymentLinkStatus;
    paymentLink: string;
    personalIdentification: string;
    amount: number;
    expiresAt: Date;
    createdAt: Date;
    updatedAt: Date;
}

export type IGetPaymentLinkResponsePayload = IPaymentLink & {
    vehicle: IPaymentLinkVehicle;
};

export type IGetPaymentLinkResponse =
    | {
          data: IGetPaymentLinkResponsePayload;
      }
    | Record<string, never>;

export interface IPaymentLinkPageParams {
    lang: AVAILABLE_LANGUAGES;
    bookingId: string;
    paymentLinkId: string;
}
