import {
    BookingStep,
    CarDetailsType,
    Showroom,
    TimeSlot,
    Toaster,
} from '@/types';
import { Option } from '@/components/checkout';
import { vehicle3DFramesResponse } from '@/types';

export interface CheckoutState {
    bookingSteps: BookingStep[];
    activeStep: number;
    selectedDay: string | null;
    selectedTime: TimeSlot | null;
    selectedCarId: string | undefined;
    selectedCar: CarDetailsType | null;
    isBookingReserved: boolean;
    reservationType: null | number;
    showroom: Showroom | null;
    calendar: any;
    availableDaysCount: number;
    availableSlotsCount: number;
    toaster: Toaster;
    confirmedBookingData: any;
    actionBtnDisabled: boolean;
    showOTP: boolean;
    otpError: string;
    selectedCity: Option | null;
    selectedArea: Option | null;
    building: string | null;
    street: string | null;
    landmark: string | null;
    paymentSrc: string;
    checkoutSubmitBtnText: string;
    isLoading: boolean;
}

export interface FinancingState {
    downPayment: number;
    tenor: number;
    idFrontURL: string;
    idBackURL: string;
    docsURLS: string[];
    application: any;
    approvedTerms: boolean;
}

export enum ModalTypes {
    Gallery = 'gallery',
    View360 = '360',
    Imperfections = 'imperfections',
}

export interface GalleryImage {
    original: string;
    thumbnail: string;
    category: number;
}

export interface ImperfectionsImage {
    newGallery: boolean;
    original: string;
    thumbnail: string;
    category: number;
    name: string;
    comment: string;
    tags: string[];
    sectionImg: string | null;
}

export interface ImperfectionsCategory {
    name: string;
    // icon: ReactElement;
    iconKey: string;
}

export interface GalleryState {
    galleryImages: GalleryImage[];
    images3d: { pending: boolean; data: string[]; error: boolean };
    imperfectionsImages: ImperfectionsImage[];
    imperfections3d: vehicle3DFramesResponse[];
    innerModalFlag: boolean;
    innerModalType: ModalTypes;
    activeMainGalleryImage: number;
    activeGalleryCategory: number;
    activeImperfectionsCategory: number;
    activeImperfectionsImage: number;
    galleryImagesCategories: string[];
    imperfectionsImagesCategories: ImperfectionsCategory[];
}
export interface FinancingCalculatorState {
    downPayment: number;
    downPaymentAmount: number;
    tenorEMI: number;
    tenorCarPrice: number;
    carPrice: number;
    monthlyInstallment: number;
    type: 'carPrice' | 'monthlyInstallments';
    isModalOpen: boolean;
}
